/**=====================
    10.  Card CSS Start
==========================**/
//Card css
.card {
  margin-bottom: $card-margin-bottom;
  border: none;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: $card-border-radious;
  box-shadow: $card-box-shadow;
  &:hover {
    box-shadow: $card-hover-box-shadow;
    transition: all 0.3s ease;
  }
  .card-header {
    background-color: $card-header-bg-color;
    padding: $card-padding;
    border-bottom: none;
    border-top-left-radius: $card-border-radious;
    border-top-right-radius: $card-border-radious;
    position: relative;
    &.card-no-border {
      border-bottom: none !important;
    }
    h5 {
      font-size: 22px;
      &:not(.mb-0),
      &:not(.m-0) {
        margin-bottom: 0;
        text-transform: $card-header-font-transform;
      }
    }
    > span {
      font-size: $card-header-span-size;
      color: $card-header-span-color;
      margin-top: 5px;
      display: block;
      letter-spacing: 1px;
    }
    .card-header-right {
      border-radius: 0 0 0 7px;
      right: 35px;
      top: 33px;
      display: inline-block;
      float: right;
      padding: 0;
      position: absolute;
      background-color: $white;
      z-index: 1;
      .card-option {
        text-align: right;
        width: 35px;
        height: 20px;
        overflow: hidden;
        transition: 0.3s ease-in-out;
        li {
          display: inline-block;
          &:first-child {
            i {
              transition: 1s;
              font-size: 16px;
              color: var(--theme-default);
              &.icofont {
                color: unset;
              }
            }
          }
        }
      }
      i {
        margin: 0 5px;
        cursor: pointer;
        color: $dark-color;
        line-height: 20px;
        &.icofont-refresh {
          font-size: 13px;
        }
      }
    }
  }
  .card-body {
    padding: $card-padding;
    background-color: $card-body-bg-color;
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .sub-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 5px;
    margin-bottom: 8px;
    font-size: 18px;
  }
  .date-range-picker {
    .daterange-card {
      .sub-title {
        font-weight: 500;
      }
    }
  }
  .card-footer {
    background-color: $card-footer-bg-color;
    border-top: $card-border-color;
    padding: $card-padding;
    border-bottom-left-radius: $card-border-radious;
    border-bottom-right-radius: $card-border-radious;
  }
}
.card-absolute {
  margin-top: 20px;
  .card-header {
    position: absolute;
    top: -20px;
    left: 15px;
    border-radius: 0.25rem;
    padding: 10px 15px;
    h5 {
      font-size: 17px;
    }
  }
  .card-body {
    margin-top: 10px;
  }
}
.card-header {
  .border-tab {
    margin-bottom: -13px;
  }
}
.custom-card {
  overflow: hidden;
  padding: 30px;
  .profile-details {
    a {
      h4 {
        margin-top: 10px;
      }
    }
  }
  .card-header {
    padding: 0;
    img {
      margin-top: -70px;
      transform: scale(1.5);
    }
  }
  .card-profile {
    text-align: center;
    img {
      height: 120px;
      padding: 7px;
      background-color: $white;
      z-index: 1;
      position: relative;
    }
  }
  .card-social {
    text-align: center;
    padding-top: 18px;
    padding-bottom: 25px;
    li {
      display: inline-block;
      border-radius: 5px;
      margin-left: 10px;
      &:nth-child(n + 2) {
        margin-left: 10px;
      }
      &:nth-child(1) {
        background-color: $fb;
      }
      &:nth-child(2) {
        background-color: $google-plus;
      }
      &:nth-child(3) {
        background-color: $twitter;
      }
      &:nth-child(4) {
        background-color: #f05142;
      }
      &:nth-child(5) {
        background-color: $linkedin;
      }
      a {
        width: 35px;
        height: 35px;
        border-radius: 5px;
        background-color: rgba($primary-color, 0.1);
        transition: all 0.3s ease;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        a {
          border: 1px solid var(--theme-default);
          background-color: $white;
          color: var(--theme-default);
        }
      }
    }
  }
  .profile-details {
    h6 {
      margin-bottom: 0;
      margin-top: 10px;
      color: $theme-body-sub-title-color;
      font-size: 14px;
    }
  }
  .card-footer {
    padding: 0;
    > div {
      + div {
        border-left: 1px solid $light-semi-gray;
      }
      padding: 10px;
      text-align: center;
      h3 {
        margin-bottom: 0;
        font-size: 24px;
      }
      h6 {
        font-size: 13px;
        color: $theme-body-sub-title-color;
      }
      h5 {
        font-size: 16px;
        margin-bottom: 0;
      }
      i {
        font-size: 24px;
        display: inline-block;
        margin-bottom: 15px;
      }
      .m-b-card {
        margin-bottom: 10px;
      }
    }
  }
}
.css-1tl6slj-MuiButtonBase-root-MuiIconButton-root {
  color: #fff !important;
}
.ShowMore {
  margin-top: 10px;
  cursor: pointer;
}
.plan li {
  padding: 10px !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}
.plan-Title {
  padding: 5px !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  display: block !important;
}
.box-readmore {
  padding: 5px !important;

  display: inline-block !important;
  margin-top: 20px;
}
.user-NoBack .vertical-timeline-element-icon {
  background-color: transparent !important;
}
.boxTrash {
  text-align: right;
}
.boxTrash i {
  color: red;
}
.boxTrash i:hover {
  cursor: pointer;
}
ul.list-receivers li {
  font-size: 10px;
  margin-right: 10px;
  display: inline-block;
  list-style-type: circle;
}
ul.list-receivers {
  list-style-type: circle;
  float: left;
  margin-left: 5px;
}
.box-receivers i {
  float: left;
  margin-top: 3px;

  color: #999999;
}
.card img {
  max-width: 100%;
}
.float-right {
  float: right !important;
}
.hideToolbar .MuiToolbar-root {
  display: none !important;
}
.w-100 {
  width: 100px;
}
.onhover-show-div {
  z-index: 1000;
  top: 30px !important;
}
.form-fa .input-rtl {
  direction: rtl;
}
.blog-fa .plan-Title {
  direction: rtl;
}
.export-iconList i {
  font-size: 20px;
  margin-right: 10px;
}
.fa-file-excel-o {
  color: green;
}
.fa-file-pdf-o {
  color: #f05142;
}
.ulbi {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}
.ulbi > li {
  margin: auto 2px;
}

.li-ba > .nav-link.active {
  background-color: #ffc500 !important;
  color: #fff;
}
.react-datepicker-popper {
  z-index: 10;
}
/**=====================
    10. Card CSS End
==========================**/
