/**=====================
    36. Blog CSS start
==========================**/
.blog-page {
  .card {
    overflow: hidden;
  }
}
.blog-box {
  .blog-wrraper {
    overflow: hidden;
  }
  img {
    border-radius: 0.25rem;
    transition: all 0.5s ease;
  }
  .top-radius-blog {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  h6 {
    font-weight: 600;
    margin: 15px 0;
    color: $theme-body-font-color;
  }
  .blog-details {
    padding-right: 20px;
    .blog-social {
      li {
        display: inline-block;
        font-size: 14px;
        color: $light-text;
        font-weight: 500;
        i {
          padding-right: 5px;
        }
        &:nth-child(n + 2) {
          border-left: 1px solid $light-gray;
          padding-left: 20px;
          margin-left: 20px;
        }
      }
    }
    hr {
      background-color: $light-gray;
      opacity: 1;
    }
  }
  .blog-details-main {
    .blog-social {
      margin: 15px 0;
      li {
        display: inline-block;
        color: $theme-body-sub-title-color;
        font-size: 12px;
        padding: 0 15px;
        font-weight: 500;
        border-right: 1px solid $light-gray;
        &:last-child {
          border-right: 0;
        }
        i {
          padding-right: 5px;
        }
      }
    }
    .blog-bottom-details {
      margin: 20px;
      font-size: 14px;
      line-height: 1.6;
      font-weight: 700;
    }
  }
  .blog-date {
    color: $light-1;
    margin-bottom: 5px;
    span {
      font-size: 36px;
      font-weight: 600;
      padding-right: 5px;
      color: var(--theme-default);
      line-height: 1;
    }
  }
  .media {
    align-items: center;
  }
  .blog-details-second {
    padding: 20px;
    .blog-post-date {
      margin-top: -48px;
      z-index: 2;
      position: relative;
      .blg-month,
      .blg-date {
        padding: 5px;
        display: block;
        width: 50px;
        font-size: 12px;
        text-align: center;
        text-transform: capitalize;
        color: $white;
        font-weight: 600;
      }
      .blg-month {
        background-color: var(--theme-default);
      }
      .blg-date {
        background-color: var(--theme-secondary);
      }
    }
    h6 {
      font-size: 18px;
      margin-top: 25px;
      margin-bottom: 10px;
    }
    p {
      color: $light-text;
      margin-bottom: 15px;
    }
    .detail-footer {
      padding: 15px 20px;
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: -20px;
      border-top: 1px solid $light-gray;
      ul.sociyal-list {
        li {
          display: inline-block;
          color: $light-text;
          text-transform: capitalize;
          font-weight: 600;
          i {
            font-size: 12px;
            margin-right: 5px;
            font-weight: 600;
          }
          &:nth-child(n + 2) {
            margin-left: 15px;
            padding-left: 15px;
            border-left: 1px solid $light-gray;
          }
        }
      }
    }
  }
  &:hover {
    .blog-wrraper {
      img {
        transform: scale(1.05);
      }
    }
  }
}
.blog-box.blog-shadow {
  height: 560px;
  &:before {
    box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease;
    border-radius: 5px;
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    width: 100%;
    bottom: 0;
  }
  &:hover {
    &:before {
      box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.65);
      transition: all 0.3s ease;
    }
  }
  .blog-details {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 40px;
    h4 {
      color: $white;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    p {
      font-size: 14px;
      color: $white;
      padding-top: unset;
      margin-top: 0;
    }
    .blog-social {
      li {
        color: $white;
      }
    }
  }
}
.blog-list {
  .blog-details {
    hr {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .blog-social {
      margin-top: 0;
    }
    h6 {
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 10px;
      line-height: 1.6;
      font-weight: 600;
      color: $theme-body-font-color;
    }
  }
  img {
    width: 100%;
    padding: 17px;
  }
}
.blog-grid {
  img {
    width: 100%;
  }
  .blog-details {
    padding: 20px;
    h6 {
      font-size: 14px;
      margin-bottom: 0;
      line-height: 1.8;
    }
  }
}
.blog-box {
  position: relative;
  max-height: 100%;
  align-items: center;
  .blog-details {
    p {
      font-size: 14px;
      line-height: 1.6;
      letter-spacing: 0.7px;
      margin-top: 10px;
      margin-bottom: 0;
      color: $light-1;
    }
    h4 {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.5;
      margin-top: 20px;
      margin-bottom: 0;
    }
    .single-blog-content-top {
      border-top: 1px solid $light-gray;
    }
    .blog-social,
    .comment-social {
      li {
        font-size: 14px;
        color: $dark;
        font-weight: 600;
      }
    }
  }
}
.comment-box {
  margin-top: 30px;
  h4 {
    font-weight: 700;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid $light-gray;
  }
  li {
    margin-bottom: 30px;
    clear: both;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    ul {
      margin-left: 100px;
      text-align: right;
    }
  }
  p {
    font-size: 14px;
    line-height: 1.8;
    text-align: justify;
    margin-bottom: 0;
    color: $light-text;
  }
  .media {
    img {
      margin-right: 45px;
      border-radius: 50%;
      height: 90px;
      padding: 7px;
      width: 90px;
      border: 1px solid $light-semi-gray;
    }
    h6 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
      text-align: left;
      span {
        padding-left: 10px;
        font-size: 13px;
        color: $light-text;
        font-weight: 400;
      }
    }
  }
  .comment-social {
    margin-left: 0;
    li {
      display: inline-block;
      color: $gray-60;
      margin-bottom: 0;
      font-weight: 500;
      text-transform: uppercase;
      &:nth-child(n + 2) {
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px solid $light-gray;
      }
      i {
        padding-right: 10px;
      }
    }
  }
}
.blog-single {
  border-radius: 15px;
  overflow: hidden;
  .blog-box {
    .banner-wrraper {
      height: 400px;
    }
    .blog-details {
      p {
        margin-top: 15px;
      }
    }
  }
  .single-blog-content-top {
    margin-top: 15px;
  }
  .comment-social {
    margin-left: unset;
  }
}
@media (max-width: 1500px) {
  .blog-box {
    &.blog-shadow {
      height: 480px;
    }
  }
}
@media screen and (min-width: 1366px) and (max-width: 1530px) {
  .blog-box {
    .blog-details-second {
      padding: 15px;
      p {
        font-size: 13px;
      }
      h6 {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 5px;
      }
      .blog-post-date {
        margin-top: -35px;
        .blg-month,
        .blg-date {
          font-size: 10px;
          padding: 3px;
        }
      }
      .detail-footer {
        margin-bottom: -15px;
        margin-left: -15px;
        margin-right: -15px;
        padding: 10px 15px;
        ul {
          &.sociyal-list {
            li {
              font-size: 12px;
              &:nth-child(n + 2) {
                margin-left: 10px;
                padding-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1400px) {
  .blog-box {
    &.blog-shadow {
      height: 440px;
    }
  }
}
@media (max-width: 1365px) {
  .blog-box {
    &.blog-shadow {
      height: 440px;
    }
  }
}
@media (max-width: 1280px) {
  .blog-box {
    &.blog-shadow {
      height: 365px;
    }
  }
}
@media (max-width: 1199px) {
  .comment-box {
    .blog-details {
      h4 {
        margin-top: 50px;
      }
    }
  }
  .blog-box {
    &.blog-shadow {
      height: 380px;
    }
    .media {
      display: block;
    }
    .blog-details {
      padding: 20px;
    }
  }
  .blog-list {
    img {
      width: 100%;
      padding: 0;
    }
  }
  .blog-single {
    .blog-box {
      .blog-details {
        padding: unset;
      }
    }
  }
}
@media (max-width: 991px) {
  .blog-box {
    .blog-details {
      p {
        margin-top: 10px;
      }
      .single-blog-content-top {
        margin-top: 20px;
      }
    }
  }
}
@media (max-width: 767px) {
  .comment-box {
    ul {
      ul {
        text-align: left;
      }
    }
  }
  .blog-single {
    .blog-box {
      .blog-details {
        h4 {
          margin-top: 10px;
        }
      }
      .blog-details {
        .single-blog-content-top {
          margin-top: 10px;
        }
        p {
          margin-top: 10px;
        }
      }
    }
  }
  .blog-box {
    .blog-details-second {
      padding: 15px;
      h6 {
        margin-top: 20px;
      }
      .detail-footer {
        padding: 15px 15px;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: -15px;
        ul.sociyal-list {
          li {
            font-size: 12px;
            &:nth-child(n + 2) {
              margin-left: 10px;
              padding-left: 10px;
            }
          }
        }
      }
      .blog-post-date {
        margin-top: -35px;
        .blg-month,
        .blg-date {
          padding: 3px;
          font-size: 10px;
        }
      }
    }
    .blog-details {
      .blog-social {
        li {
          &:nth-child(n + 2) {
            padding-left: 10px;
            margin-left: 10px;
          }
        }
      }
      .blog-social,
      .comment-social {
        li {
          font-size: 13px;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .blog-single {
    .comment-box {
      .comment-social {
        li {
          font-size: 12px;
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .blog-box {
    .blog-details-second {
      h6 {
        margin-top: 15px;
        margin-bottom: 5px;
      }
      p {
        margin-bottom: 10px;
      }
    }
  }
}
/**=====================
    36. Blog CSS ends
==========================**/
