/**=====================
    02. Typography css start
==========================**/
//Custom css Start
body {
  font-size: $body-font-size;
  overflow-x: hidden;
  font-family: "Vazir";
  color: $dark;
}
ul {
  padding-left: $ul-padding-left;
  list-style-type: none;
  margin-bottom: 0;
}
* a {
  color: var(--theme-default);
  text-decoration: none;
  letter-spacing: 1px;
}
a {
  &:hover {
    text-decoration: none;
    color: var(--theme-default);
  }
  &[class*="badge-"] {
    &:hover {
      color: $white;
    }
  }
}
.btn {
  font-size: 14px;
  font-weight: 500;
  font-family: $font-roboto, "sans-serif";
}
*.btn:focus {
  box-shadow: $btn-focus-box-shadow !important;
}
h1,
.h1 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
}
h2,
.h2 {
  font-size: 24px;
  letter-spacing: 0.5px;
  font-weight: 700;
}
h3,
.h3 {
  font-size: 20px;
  letter-spacing: 0.5px;
  font-weight: 600;
}
h4,
.h4 {
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
}
h5,
.h5 {
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 500;
}
h6,
.h6 {
  letter-spacing: 0.5px;
  font-weight: 400;
}
p {
  font-size: $paragraph-font-size;
  line-height: $paragraph-line-height;
  letter-spacing: $paragraph-letter-spacing;
}
code {
  color: $code-tag-color !important;
  background-color: $code-tag-bg-color;
  padding: $code-tag-padding;
  margin: $code-tag-margin;
  border-radius: $code-tag-border-radious;
}
.media {
  display: flex;
  align-items: flex-start;
  .media-body {
    flex: 1;
  }
}
blockquote {
  border-left: $blockquote-border;
  padding: $blockquote-padding;
  &.text-end {
    border-right: $blockquote-border;
    border-left: unset;
  }
  &.text-center {
    border: 0;
  }
}
.figure {
  &.text-center {
    blockquote {
      border: none !important;
    }
  }
  &.text-end {
    blockquote {
      border-left: none !important;
      border-right: $blockquote-border;
    }
  }
}
.row {
  > div {
    position: relative;
  }
}
:focus {
  outline: none;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
svg {
  vertical-align: baseline;
}
input {
  &:focus {
    outline-color: $transparent-color;
  }
}
label {
  margin-bottom: 0.5rem;
}
.media-widgets {
  .media-body {
    margin-bottom: 30px;
  }
}
ol.simple-list {
  .list-group-item {
    display: list-item;
  }
}
//Custom css End
//Typography css start
.typography {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin: 20px 0;
    margin-top: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  small {
    padding-left: $small-tag-padding;
    color: $small-tag-color;
  }
}
pre {
  background-color: $pre-tag-bg-color;
  padding: $pre-tag-padding;
}
//Typography css end
.owl-theme {
  .owl-dots {
    .owl-dot {
      span {
        background: lighten($primary-color, 20%);
      }
      &.active,
      &:hover {
        span {
          background: var(--theme-default);
        }
      }
    }
  }
  .owl-nav {
    &.disabled {
      & + .owl-dots {
        margin-bottom: -10px;
        margin-top: 25px;
      }
    }
  }
}
// modal page
.modal {
  .modal-dialog {
    .modal-header {
      button.btn-close {
        font-size: 14px;
        z-index: 2;
      }
    }
  }
  .modal-body {
    .card {
      box-shadow: none !important;
    }
  }
}
.modal-dialog {
  .modal-content {
    .modal-body {
      p {
        a {
          margin-right: 0;
        }
      }
    }
  }
}
// index page notify
.alert-theme {
  button {
    top: 45px !important;
    right: 20px !important;
    color: var(--theme-default);
  }
  span {
    + span {
      + span {
        background-color: $white;
        color: var(--theme-default);
        border-radius: 4px;
        box-shadow: 0 2px 14px -3px rgba($black, 0.2);
      }
    }
  }
  i {
    padding: 20px;
    padding-right: 0 !important;
    margin-right: 20px !important;
  }
  .progress {
    margin-top: -2px !important;
    height: 2px;
    width: 100%;
    .progress-bar {
      background-color: var(--theme-default);
    }
  }
}
.alert-copy {
  border: 1px solid var(--theme-default);
  line-height: 1;
  display: inline-block;
  width: 300px;
  color: $white;
  background-color: var(--theme-default);
  .btn-close {
    box-shadow: none;
    color: $white;
    line-height: 1.4;
    font-weight: 100;
    &:focus {
      outline: none;
    }
  }
}
.CodeMirror {
  top: 0 !important;
}
.CodeMirror-fullscreen {
  top: 50px !important;
}
.form-inline .form-group {
  display: flex;
}
.input-group-text {
  i {
    line-height: 1.5;
  }
}
.listing {
  ol {
    margin-bottom: 0;
  }
  dl {
    margin-bottom: 0;
    dd {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/**=====================
    02. Typography css Ends
==========================**/
